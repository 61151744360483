// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-mdx": () => import("./../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-about-mdx": () => import("./../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-avatar-mdx": () => import("./../src/pages/avatar.mdx" /* webpackChunkName: "component---src-pages-avatar-mdx" */),
  "component---src-pages-blog-ad-mdx": () => import("./../src/pages/blog/ad.mdx" /* webpackChunkName: "component---src-pages-blog-ad-mdx" */),
  "component---src-pages-blog-ant-design-custom-theme-toggling-mdx": () => import("./../src/pages/blog/Ant-Design-custom-theme-toggling.mdx" /* webpackChunkName: "component---src-pages-blog-ant-design-custom-theme-toggling-mdx" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-principles-mdx": () => import("./../src/pages/blog/principles.mdx" /* webpackChunkName: "component---src-pages-blog-principles-mdx" */),
  "component---src-pages-blog-recoil-your-new-state-manager-mdx": () => import("./../src/pages/blog/Recoil-your-new-state-manager.mdx" /* webpackChunkName: "component---src-pages-blog-recoil-your-new-state-manager-mdx" */),
  "component---src-pages-blog-simple-drawing-and-animation-inside-canvas-with-react-mdx": () => import("./../src/pages/blog/Simple drawing and Animation inside canvas with React.mdx" /* webpackChunkName: "component---src-pages-blog-simple-drawing-and-animation-inside-canvas-with-react-mdx" */),
  "component---src-pages-blog-storybook-hierarchy-mdx": () => import("./../src/pages/blog/storybook-hierarchy.mdx" /* webpackChunkName: "component---src-pages-blog-storybook-hierarchy-mdx" */),
  "component---src-pages-index-mdx": () => import("./../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-notes-index-mdx": () => import("./../src/pages/notes/index.mdx" /* webpackChunkName: "component---src-pages-notes-index-mdx" */)
}

